import React from "react"
import ReactDOM from "react-dom"
// import "./index.css";
import App from "./app"
import "./style/app.less"
import reportWebVitals from './reportWebVitals';
console.log("process env", process.env.NODE_ENV)

ReactDOM.render(<App />, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";
const Dashboard = lazy(() =>
  import(/*webpackChunkName:'DashboardPage'*/ "@/pages/Dashboard")
);
const Admin = lazy(() =>
  import(/*webpackChunkName:'AdminPage'*/ "@/pages/Admin")
);

const Questionnaire = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Questionnaire")
);

const Assessment = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/assessment/ListAssessment")
);

const NewAssessment = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/assessment/createAssessment")
);

const Competence = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Competence")
);

const Badge = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Badge")
);
const Degree = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Degree")
);
const Avatar = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Avatar")
);
const Cases = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Cases")
);
const Enigma = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Enigma")
);
const Mission = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Mission")
);
const Card = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Card")
);
const Challenge = lazy(() =>
    import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Challenge")
);

const Logout = lazy(() =>
  import(/*webpackChunkName:'LogoutPage'*/ "@/pages/Logout")
);
const NotFound = lazy(() =>
  import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);
const Step = lazy(() =>
  import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/Step")
);
const Response = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/Response")
);
const Group = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/Group")
);
const Feedback = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/Feedback")
);
const Dilema = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/Dilema")
);
const Users = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/Users")
);
const CreateUser = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/CreateUser")
);

const StudentStateView = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/StudentStateView")
);

const ListaDocs = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/docs/ListaDocs")
);
const EditDocs = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/docs/EditDocs")
);
const CompetenceTransversal = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/CompetenceTransversal")
);
const EditAssessment = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/assessment/editAssessment")
);

export default function AppRouter() {
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/" component={Dashboard} exact />
          <PrivateRoute path="/student/:uuid/state" component={StudentStateView} exact />
          <PrivateRoute component={ListaDocs} path="/docs" exact />
          <PrivateRoute component={CompetenceTransversal} path="/transversals" exact />
          <PrivateRoute component={EditDocs} path="/newdoc" exact />
          <PrivateRoute component={EditDocs} path="/editdoc/:id" exact />
          <PrivateRoute component={Degree} path="/degree" exact />
          <PrivateRoute component={Group} path="/groups" exact />
          <PrivateRoute component={Feedback} path="/feedbacks" exact />

          <PrivateRoute component={Users} path="/users" exact />
          <PrivateRoute component={CreateUser} path="/newuser" exact />
          <PrivateRoute component={Badge} path="/badges" exact />
          <PrivateRoute component={Competence} path="/competence" exact />
          <PrivateRoute component={Questionnaire} path="/questionnaire" exact />
          <PrivateRoute component={Assessment} path="/assessment" exact />
          <PrivateRoute component={Dilema} path="/dilema" exact />
          <PrivateRoute component={NewAssessment} path="/newassessment" exact />
          <PrivateRoute component={EditAssessment} path="/editassessment/:uuid" exact />

          <PrivateRoute component={Avatar} path="/avatar" exact />
          <PrivateRoute component={Cases} path="/cases" exact />
          <PrivateRoute component={Enigma} path="/enigma" exact />
          <PrivateRoute component={Mission} path="/mission" exact />
          <PrivateRoute component={Card} path="/card" exact />
          <PrivateRoute component={Challenge} path="/challenge" exact />
          <PrivateRoute component={Admin} path="/admin" exact />
          <PrivateRoute component={Step} path="/step" exact />
          <PrivateRoute component={Response} path="/response" exact />
          <PrivateRoute component={Logout} path="/logout" exact />
          <PublicRoute path="/login" render={() => <Redirect to="/" />} />
          <Route
            path="*"
            component={NotFound}
            render={() => <Redirect to="/notfound" />}
          />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}

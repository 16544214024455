import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  SettingOutlined,
  UserOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  DashboardOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

function Navigation() {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const menuItems = [
    {
      name: "Dashboard",
      icon: <DashboardOutlined />,
      link: "/dashboard",
      key: 1,
    },
    {
      name: "Users",
      icon: <DashboardOutlined />,
      link: "/users",
      key: 1122,
    },
    /*{
      name: "Steps",
      icon: <DashboardOutlined />,
      link: "/step",
      key: 11,
    },*/
    {
      name: "Degrees",
      icon: <CustomerServiceOutlined />,
      link: "/degree",
      key: 21,
    },
    {
      name: "Groups",
      icon: <CustomerServiceOutlined />,
      link: "/groups",
      key: 2221,
    },
    {
      name: "Feedbacks",
      icon: <CustomerServiceOutlined />,
      link: "/feedbacks",
      key: 2231,
    },

    {
      name: "Badges",
      icon: <CustomerServiceOutlined />,
      link: "/badges",
      key: 5,
    },
    {
      name: "Competences",
      icon: <CustomerServiceOutlined />,
      link: "/competence",
      key: 2,
    },
    {
      name: "Comp Transversals",
      icon: <CustomerServiceOutlined />,
      link: "/transversals",
      key: 2222,
    },

    {
      name: "Questionnaires",
      icon: <CustomerServiceOutlined />,
      link: "/questionnaire",
      key: 3,
    },
    {
      name: "Assessment",
      icon: <CustomerServiceOutlined />,
      link: "/assessment",
      key: 4,
    },
    {
      name: "Challenges",
      icon: <CustomerServiceOutlined />,
      link: "/challenge",
      key: 55,
    },
    
    {
      name: "Enigmas",
      icon: <CustomerServiceOutlined />,
      link: "/card",
      key: 154,
    },

    {
      name: "Dilema",
      icon: <CustomerServiceOutlined />,
      link: "/dilema",
      key: 54,
    },
    {
      name: "Docs",
      icon: <CustomerServiceOutlined />,
      link: "/docs",
      key: 514,
    },
    // {
    //   name: "Responses",
    //   icon: <CustomerServiceOutlined />,
    //   link: "/response",
    //   key: 541,
    // },

    // {
    //   name: "Enigmas",
    //   icon: <CustomerServiceOutlined />,
    //   link: "/enigma",
    //   key: 52,
    // },
    // {
    //   name: "Missions",
    //   icon: <CustomerServiceOutlined />,
    //   link: "/mission",
    //   key: 53,
    // },

    // {
    //   name: "Cases",
    //   icon: <CustomerServiceOutlined />,
    //   link: "/cases",
    //   key: 51,
    // },
  

    //
    // {
    //   name: "Avatar items",
    //   icon: <CustomerServiceOutlined />,
    //   link: "/avatar",
    //   key: 6,
    // }



  ];

  const getCurrentRoute = () => {
    var ret = ["1"];

    menuItems.map((item) => {
      if (location.pathname.includes(item.link)) {
        ret = ["" + item.key + ""];
      }
    });


    return ret;
  };


  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{
          zIndex: 1000,
        }}
      >
        <div className="logo" />
        <Menu theme="dark" selectedKeys={getCurrentRoute()} mode="inline">
          {menuItems.map((menuItem) => {
            return (
                <Menu.Item key={menuItem.key}>
                  <Link to={menuItem.link}>{menuItem.name}</Link>
                </Menu.Item>
            );
          })}

        </Menu>
      </Sider>
    </>
  );
}
export default Navigation;

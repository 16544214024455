console.log(
  "process env REACT_APP_BACKEND_URL",
  process.env.REACT_APP_BACKEND_URL
)

export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL
// export const API_BASE_URL = "https://wild-puce-reindeer-sari.cyclic.app/api/";
export const ACCESS_TOKEN_NAME = "admin_AuthTECBK"
export const AUTH = "admin_bk_tecAuth";
export const USER= "admin_bk_tecUserAuth";
export const TOKEN=  "admin_bk_tecToken";
export const TOKENREFRESH=  "admin_bk_tecRefreshToken";
export const REMEMBER = "admin_bk_tecRemember";
